@define-mixin CardsSectionColorVariant $backgroundColor, $headingColor {
    background-color: $backgroundColor;

    & .Heading {
        color: $headingColor;
    }
}

.CardsSection {
    padding-top: 32px;
    padding-bottom: 48px;

    @media (--tablet) {
        padding-top: 64px;
        padding-bottom: 80px;
    }

    & .ListWrapper {
        @media (--mobileOnly) {
            margin-left: calc(-1 * var(--wrapperHorizontalPadding));
            margin-right: calc(-1 * var(--wrapperHorizontalPadding));
        }
    }
}

.Heading {
    @mixin sectionHeading;
    margin-bottom: 24px;
}

.TeaserWrapper {
    margin-bottom: 32px;

    @media (--tablet) {
        @mixin grid;
        grid-template-columns: repeat(12, 1fr);
    }
}

.Teaser {
    @mixin sectionTeaser;
    @mixin hyphenateWords manual;

    @media (--tablet) {
        grid-column: 3 / span 8;
    }
}

.Variant--light {
    @mixin CardsSectionColorVariant var(--whiteBaseColor), var(--blackBaseColor);
}

.Variant--dark {
    @mixin CardsSectionColorVariant var(--blackBaseColor), var(--whiteBaseColor);
}
