@define-mixin CardColorVariant $backgroundColor, $backgroundColorOnHover, $contentColor {
    --textColor: $contentColor;
    color: $contentColor;
    background-color: $backgroundColor;

    &:hover {
        background-color: $backgroundColorOnHover;
    }
}

.Card {
    @mixin buttonTransitionBehavior background-color;
    padding: 24px;
    cursor: pointer;

    &:hover {
        & .Link {
            translate: 0;
        }
    }

    & .Link {
        @mixin buttonTransitionBehavior translate;
        display: block;
        width: min-content;
        padding-top: 8px;
        translate: -8px 0;
        margin-left: auto;
    }
}

.Heading {
    @mixin h40;
    @mixin hyphenateWords manual;
    font-family: var(--serifFontFamily);
    font-weight: 600;
    margin: 0 0 32px;
}

.Content {
    @mixin hyphenateWords manual;
    @mixin p10;
    margin-bottom: 64px;
}

.LinkIcon {
    @mixin fixedRatioSvgIcon 40px;
}

.Variant--rounded {
    @mixin themeRoundedCornersMedium;
}

.Variant--light {
    @mixin CardColorVariant var(--blackLighten60Color), var(--blackLighten50Color), var(--blackBaseColor);
}

.Variant--dark {
    @mixin CardColorVariant var(--blackLighten12Color), var(--blackLighten17Color), var(--whiteBaseColor);
}
