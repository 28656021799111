.ListWrapper {
    background-color: var(--blackLighten50Color);
    margin-bottom: 32px;

    @media (--tablet) {
        background: initial;
        padding: 0;
    }
}

.TabList {
    @media (--tablet) {
        justify-content: center;
    }
}

.TabContent {
    @mixin clearList;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media (--desktop) {
        @mixin grid;
        grid-template-columns: repeat(3, 1fr);

        & .Card {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        & .CardContent {
            flex: 1;
        }
    }
}
