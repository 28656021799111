@define-mixin TabsColorVariant $textColor, $backgroundColor, $backgroundColorHover, $textColorSelected,
    $backgroundColorSelected {
    & .TabList {
        background-color: $backgroundColor;
    }

    & .Tab {
        background-color: $backgroundColor;
        color: $textColor;

        @media (--supportsHover) {
            &:hover {
                background: $backgroundColorHover;
            }
        }

        &.Tab--selected {
            background: $backgroundColorSelected;
            color: $textColorSelected;
        }
    }
}

@define-mixin TabsArrowsColorVariant $backgroundColor, $iconColor {
    & .Arrow {
        background-color: $backgroundColor;
    }

    & .Arrow--prev {
        box-shadow: 5px 0 20px 5px $backgroundColor;
    }

    & .Arrow--next {
        box-shadow: -5px 0 20px 5px $backgroundColor;
    }

    & .ArrowIcon {
        @mixin fixedRatioSvgIcon 24px, $iconColor;
    }
}

.TabPanel {
    @mixin fadeInAnimation;
}

.TabListWrapper {
    position: relative;
    overflow-y: hidden;
}

.TabList {
    display: flex;
    flex-shrink: 0;
    align-self: center;
    width: 100%;
    padding: 8px 32px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    touch-action: pan-x;

    @media (--tablet) {
        flex-wrap: wrap;
        width: fit-content;
        min-width: initial;
        margin: 0 auto;
        padding: 0;
    }
}

.TabList--rounded {
    @media (--tablet) {
        @mixin themeRoundedCornersMedium;
    }

    & .Tab {
        @mixin themeRoundedCornersMedium;
    }
}

.Tab {
    @mixin buttonBase;
    @mixin buttonTransitionBehavior background-color;

    @mixin onFocus {
        @mixin focusVisible -4px;
        z-index: 1;
    }
    display: inline-flex;
    position: relative;
    font-weight: 400;
    padding: 16px 32px;
    border: none;
    flex-shrink: 1;
    min-width: 50vw;
    max-width: 70vw;
    scroll-snap-align: center;

    @media (--tablet) {
        padding: 24px 32px;
        min-width: unset;
        max-width: unset;
        flex-shrink: 0;
    }

    &.Tab--selected {
        @mixin visuallyBold;
    }
}

.Arrow {
    @mixin centerElements;

    @mixin onFocus {
        @mixin focusVisible -4px;
        z-index: 1;
    }
    position: absolute;
    top: 0;
    z-index: 1;
    height: 100%;
    width: 40px;
    transition: scale 0.2s ease-in-out;

    @media (--tablet) {
        display: none;
    }

    &:disabled {
        scale: 0 1;

        &.Arrow--prev {
            transform-origin: center left;
        }

        &.Arrow--next {
            transform-origin: center right;
        }
    }
}

.Arrow--prev {
    left: 0;
}

.Arrow--next {
    right: 0;
}

.Variant--light {
    @mixin TabsColorVariant var(--blackLighten10Color), var(--blackLighten50Color), var(--blackLighten40Color),
        var(--whiteBaseColor), var(--blackLighten17Color);
    @mixin TabsArrowsColorVariant var(--blackLighten50Color), var(--blackBaseColor);
}

.Variant--dark {
    @mixin TabsColorVariant var(--blackLighten60Color), var(--blackLighten12Color), var(--blackLighten10Color),
        var(--whiteBaseColor), var(--blackLighten20Color);
    @mixin TabsArrowsColorVariant var(--blackLighten12Color), var(--whiteBaseColor);
}
