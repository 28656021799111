.Wrapper {
    @mixin wrapper;

    @media (--desktopHD) {
        @mixin grid;
        grid-template-columns: repeat(12, 1fr);
    }
}

.Inner {
    @media (--desktopHD) {
        grid-column: calc(var(--layoutGridOffset, 0) + 1) / span var(--layoutGridSpan, 12);
    }
}
